import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  tables: '',
  tableType: false,
  seating: '',
  seatingType: false,
  woodenDanceFloor: 'yes',
  optionlighting: 'yes',
  mattedFlooring: 'yes',
  mirrorBallSpotLights: '',
  cateringTents: '',
  optionStage: '',
  rusticLogBar: '',
  woodenFirePit: '',
  chillOutPack: '',
  rusticCakeTable: '',
  woodenBarrel: '',
}

export const selectedOptionsSlice = createSlice({
  name: 'selectedOptions',
  initialState,
  reducers: {
    storeSelected: (state, action) => {
      let id = action.payload.fieldID;
      let value = action.payload.value;
      state[id] = value;
    }
  },
})

// Action creators are generated for each case reducer function
export const { storeSelected } = selectedOptionsSlice.actions

export default selectedOptionsSlice.reducer