import { useSelector, useDispatch } from "react-redux";
import { activeStep, enabledSteps } from '../steps/stepsSlice';
import { useNavigate } from 'react-router-dom';
import { SelectedTent } from './selectedTent'
import { v4 as uuidv4 } from 'uuid';

export const SelectedTentList = () => {

    const selectedTents = useSelector((state) => state.selectedTents)
    const steps = useSelector((state) => state.steps)
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const buildQuote = (e) => {
        e.preventDefault();
        dispatch(activeStep(3))
        dispatch(enabledSteps([1,2,3]))
        navigate("/quote-details")
    }

    if ( selectedTents.length > 0 ) {

        return (
            <>
                { 
                // tents that have been added to custom list
                }
                <div className="tent-type">
                    {selectedTents.map(selected => { 
                        return <SelectedTent key={uuidv4()} id={selected.id} seated={selectedTents.seated} activestep={steps.active} 
                        addDelivery={selectedTents.addDelivery}
                        {...selected} />
                    })}
                </div>
                { steps.active == 2 &&
                <button type="button" onClick={ buildQuote } className="button button--icon-right button--c-white button--no-width" href="/">Let's start talking <img src="/images/icon-arrow-right-dark.svg" alt="" className="icon--svg"></img></button>
                }
            </>
        )
        
    } else {
        return <p>You currently have no items added&hellip;</p>
    }
}