import React, { useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { addTent } from "../features/selectedTents/selectedTentsSlice";
import _ from 'lodash'

const ShortlistButton = ({ tent, ...props }) => {

    const selectedTents = useSelector((state) => state.selectedTents)

    //const optionsChanged = useSelector((state) => state.tents.optionsChanged)

    const dispatch = useDispatch();

    const [ shortlisted, setShortlisted ] = useState(false);

    const [ inList, setlisted ] = useState(false);

    // @todo : change to use tent.id
    function doShortlist() {
        if (!_.find(selectedTents, tent)) {
            dispatch(addTent(tent))
            setShortlisted(true)
        } else {
            setlisted(true)
        }

        setTimeout(() => {
            setShortlisted(false)
            setlisted(false)
        }, 1000);
    }

    // if ( optionsChanged !== false && _.find(selectedTents, tent) ) {
    //     setShortlisted(false)
    // }

    return (
        <div className="shortlist--wrapper">
        <button className={ "button button--icon-left button--no-width" + (shortlisted ? " added" : "") + (inList ? " animate__animated animate__headShake" : "")  } onClick={ doShortlist }>
            { shortlisted &&
            <img src="images/icon-check.svg" alt="" className="icon--check icon--svg"/>
            }
            { !shortlisted &&
            <img src="images/icon-plus.svg" alt="" className="icon--svg"/> 
            }
            { shortlisted ? 'Shortlisted' : 'Shortlist' }
        </button> 
        { inList &&
            <small className="alert-listed">Already Listed!</small>
        }
        </div>
    );
};

export default ShortlistButton;