import { createSlice } from '@reduxjs/toolkit'
import _ from 'lodash'

const initialState = [] 

export const selectedTentsSlice = createSlice({
  name: 'selectedTents',
  initialState,
  reducers: {
    addTent: (state, action) => {
      const newTent = action.payload
      //if (!_.find(state, newTent)) {
        state.push(newTent)
      //}
    },
    removeTent: (state, action) => {
        
        let remove = _.findIndex(state, function(o) { return _.isEqual(o.selected, action.payload)  });

        state = state.splice(remove, 1);
    },
  },
})

// Action creators are generated for each case reducer function
export const { addTent, removeTent } = selectedTentsSlice.actions

export default selectedTentsSlice.reducer