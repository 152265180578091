import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  errors: '',
}

export const formErrorsSlice = createSlice({
  name: 'formErrors',
  initialState,
  reducers: {
    formErrorsResponse: (state, action) => {
      let errorsData = action.payload
      state.errors = errorsData;
    }
  },
})

// Action creators are generated for each case reducer function
export const { formErrorsResponse } = formErrorsSlice.actions

export default formErrorsSlice.reducer