import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  active: 1,
  enabled: [1]
}
export const stepsSlice = createSlice({
  name: 'steps',
  initialState,
  reducers: {
    activeStep: (state, action) => {
      state.active = action.payload
    },
    enabledSteps: (state, action) => {
      state.enabled = action.payload
    }
  },
})

// Action creators are generated for each case reducer function
export const { activeStep, enabledSteps } = stepsSlice.actions

export default stepsSlice.reducer