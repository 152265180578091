import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from "react-redux";
import { activeStep, enabledSteps } from '../features/steps/stepsSlice';
import { updateEvent } from '../features/event/eventsSlice';
import { storeSelected } from "../features/selectedOptions/selectedOptionsSlice";
import { useFormik } from "formik";
import * as Yup from "yup";
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Checkbox from '@material-ui/core/Checkbox';
import isValid from "uk-postcode-validator";

const useStyles = makeStyles(() => ({
    input: {
        color: "white",
    },
    numberinput: {
        '& input[type=number]': {
            '-moz-appearance': 'textfield'
        },
        '& input[type=number]::-webkit-outer-spin-button': {
            '-webkit-appearance': 'none',
            margin: 0
        },
        '& input[type=number]::-webkit-inner-spin-button': {
            '-webkit-appearance': 'none',
            margin: 0
        }
    }
}));

export const EventDetailsForm = (props) => {
    const navigate = useNavigate();
    const classes  = useStyles();
    const dispatch = useDispatch();
    const eventStore = useSelector((state) => state.event)
    const selectedOptionsStore = useSelector((state) => state.selectedOptions)

    const formik = useFormik({
        initialValues: {
            eventDate: (eventStore !== undefined && eventStore.eventDate ? eventStore.eventDate : ""),
            postcode: (eventStore !== undefined && eventStore.postcode ? eventStore.postcode : ""),
            seatedGuests: (eventStore !== undefined && eventStore.seatedGuests ? eventStore.seatedGuests : ""),
            totalGuests: (eventStore !== undefined && eventStore.totalGuests ? eventStore.totalGuests : ""),
            filterTentsTipi: (eventStore !== undefined && eventStore.filterTentsTipi ? eventStore.filterTentsTipi : ""),
            filterTentsSailCloth: (eventStore !== undefined && eventStore.filterTentsSailCloth ? eventStore.filterTentsSailCloth : ""),
            filterTentsStretch: (eventStore !== undefined && eventStore.filterTentsStretch ? eventStore.filterTentsStretch : ""),
        },
        validationSchema: Yup.object({
            eventDate: Yup.date(),
            seatedGuests: Yup.number().integer('This must be an integer'),
            totalGuests: Yup.number().max(300, 'If you plan on having more than 300 guests please contact us directly on 01206 298074 or email us at info@eventsundercanvas.co.uk').required('Please enter the approximate number of guests'), 
            postcode: Yup.string().test('postcode-uk', 'Please enter a valid postcode', function(value) { return ( value ? isValid(value) : true ) } ),
        }),
        onSubmit: (values) => {
            dispatch(updateEvent(values));

            if ( selectedOptionsStore.tables !== "" ) {

                if (selectedOptionsStore.tableType == false) {

                    let storeTablesData = {
                        fieldID: 'tables',
                        value: Math.ceil(values.seatedGuests / 6)
                    }
    
                    dispatch( storeSelected(storeTablesData) )

                }

                if (selectedOptionsStore.tableType == true) {

                    let storeTablesData = {
                        fieldID: 'tables',
                        value: Math.ceil(values.seatedGuests / 10)
                    }
    
                    dispatch( storeSelected(storeTablesData) )
                }

                if (selectedOptionsStore.seatingType == false) {

                    let storeSeatingData = {
                        fieldID: 'seating',
                        value: Math.ceil(values.seatedGuests / 6) * 2
                    }
    
                    dispatch( storeSelected(storeSeatingData) )
                }

                if (selectedOptionsStore.seatingType == true) {

                    let storeSeatingData = {
                        fieldID: 'seating',
                        value: values.seatedGuests
                    }
    
                    dispatch( storeSelected(storeSeatingData) )
                }
            }

            dispatch(activeStep(2))
            dispatch(enabledSteps([1,2]))
            navigate("/event-options")
        },
    });
    
    return (
        <form onSubmit={ formik.handleSubmit }>
            <div className="input-container">

                <div className="field-wrapper">
                    <TextField 
                        InputProps={{ className: classes.input, inputProps: { min: new Date().toISOString().slice(0, 10) }  }}
                        InputLabelProps={{ className: classes.input,  shrink: true, }}
                        style={{ width: "300px" }}
                        color="primary"
                        variant="filled"
                        id="eventDate"
                        name="eventDate"
                        type="date"
                        label="Event Date (if known)"
                        error={formik.touched.eventDate && Boolean(formik.errors.eventDate)}
                        helperText={formik.touched.eventDate && formik.errors.eventDate}
                        onChange={ formik.handleChange }
                        onBlur={ formik.handleBlur }
                        value={ formik.values.eventDate }
                    />
                </div>

                <div className="field-wrapper">
                    <TextField 
                        InputProps={{ className: classes.input }}
                        InputLabelProps={{ className: classes.input }}
                        style={{width: "300px"}}
                        variant="filled"
                        label="Venue Postcode (if known)"
                        id="postcode"
                        name="postcode"
                        type="text"
                        error={formik.touched.postcode && Boolean(formik.errors.postcode)}
                        helperText={formik.touched.postcode && formik.errors.postcode}
                        onChange={ formik.handleChange }
                        onBlur={ formik.handleBlur }
                        value={ formik.values.postcode }
                   />
                </div>

                <div className="field-wrapper">
                    <TextField 
                        InputProps={{ className: classes.input }}
                        InputLabelProps={{ className: classes.input }}
                        style={{width: "300px"}}
                        variant="filled"
                        label="Total Guests Attending"
                        id="totalGuests"
                        name="totalGuests"
                        //type="number"
                        type="text" 
                        inputMode="numeric"
                        pattern="\d*"
                        error={formik.touched.totalGuests && Boolean(formik.errors.totalGuests)}
                        helperText={formik.touched.totalGuests && formik.errors.totalGuests}
                        onChange={ formik.handleChange }
                        onBlur={ formik.handleBlur }
                        value={ formik.values.totalGuests }
                    />
                </div>

                <div className="field-wrapper">
                    <TextField 
                        InputProps={{ className: classes.input }}
                        InputLabelProps={{ className: classes.input }}
                        style={{width: "300px"}}
                        variant="filled"
                        label="No. of Formal Dining Seats Required"
                        id="seatedGuests"
                        name="seatedGuests"
                        //type="number"
                        type="text" 
                        inputMode="numeric"
                        pattern="\d*"
                        error={formik.touched.seatedGuests && Boolean(formik.errors.seatedGuests)}
                        helperText={formik.touched.seatedGuests && formik.errors.seatedGuests}
                        onChange={ e => { 
                            formik.handleChange(e);
                        }}
                        onBlur={ formik.handleBlur }
                        value={ formik.values.seatedGuests }
                    />
                </div>
                <p>(enter zero if casual party)</p>
                
                 <div className="field-wrapper">
                    <label htmlFor="filterTentsTipi">Interested In Tipis</label>
                    <div>
                    <a href="https://eventsundercanvas.co.uk/tipi-hire/" target="_blank">
                    <img src="/images/icon-info.svg" height="18" width="18" className="icon-info icon-info--tipi" data-fbox="tipi" />
                    </a>
                    <Checkbox
                        color="primary"
                        id="filterTentsTipi"
                        name="filterTentsTipi"
                        onChange={ formik.handleChange }
                        onBlur={ formik.handleBlur }
                        value="1"
                        checked={ formik.values && formik.values.filterTentsTipi[0] == '1' ? true : false }
                    />
                    </div>
                </div>

                 <div className="field-wrapper">
                    <label htmlFor="filterTentsSailCloth">Interested In Sailcloth</label>
                    <div>
                    <a href="https://eventsundercanvas.co.uk/tipis-tents/sailcloth-tents/" target="_blank">
                    <img src="/images/icon-info.svg" height="18" width="18" className="icon-info icon-info--sc" data-fbox="sailcloth" />
                    </a>
                    <Checkbox
                        color="primary"
                        id="filterTentsSailCloth"
                        name="filterTentsSailCloth"
                        onChange={ formik.handleChange }
                        onBlur={ formik.handleBlur }
                        value="1"
                        checked={ formik.values && formik.values.filterTentsSailCloth[0] == '1' ? true : false }
                    />
                    </div>
                </div>

                 <div className="field-wrapper">
                    <label htmlFor="filterTentsStretch">Interested In Stretch Tents </label>
                    <div>
                    <a href="https://eventsundercanvas.co.uk/stretch-tents/" target="_blank">
                    <img src="/images/icon-info.svg" height="18" width="18" className="icon-info icon-info--st" />
                    </a>
                    <Checkbox
                        color="primary"
                        id="filterTentsStretch"
                        name="filterTentsStretch"
                        onChange={ formik.handleChange }
                        onBlur={ formik.handleBlur }
                        value="1"
                        checked={ formik.values && formik.values.filterTentsStretch[0] == '1' ? true : false }
                    />
                    </div>
                </div>

                <button type="submit" className="button button--large button--c-sec">Tents &amp; Options <img src="images/icon-arrow-right-s.svg" alt="" /></button>
            </div>
        </form>
    )
}