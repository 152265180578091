import { useSelector } from "react-redux";
import { EmailForm } from "./EmailForm";
import { SelectedTentList } from '../features/selectedTents/SelectedTents'
import { createTheme, ThemeProvider } from '@material-ui/core/styles';

const darkTheme = createTheme({
  palette: {
    mode: 'dark',
     primary: {
      contrastText: "#FFFFFF",
      dark: "#FFFFFF",
      main: "#FFFFFF",
      light: "#FFFFFF",
     },
  },
  overrides: {
      // Checkbox & Select arrow
      MuiSvgIcon: {
          root: {
              fill: "#FFFFFF",
          }
      },
      MuiFormLabel: {
        root: {
          color: '#FFF'
        }
      
      },
      MuiSelect: {
        root: {
          color: '#FFF',
          textAlign: 'left'
        }
      
      }
    },
});

export const QuoteDetails = (props) => {

    const selectedTents = useSelector((state) => state.selectedTents)

    //let height = document.body.scrollHeight;

    return (
      <div className="wrapper">
        <section className="step-3 step--contact-details">
            <header>
                <h2>Let's Start Talking</h2>
                <p>We need to check if your selected tents are available on your event date, please complete the<br></br> form below and a member of the team will come back to you with our availability.</p>
                <small>*Please note all quotes are not valid until confirmed by a member of the team.</small>
            </header>
            <div className="form" id="emailForm">
                <ThemeProvider theme={darkTheme}>
                    <EmailForm />
                </ThemeProvider>
            </div>
            
        </section>
        <div className="side right">
          <h3>Your Selection</h3>
          <div className="side--block">
            <SelectedTentList tents={selectedTents} /> 
          </div>
      </div>
    </div>
    )
}