import { EventDetailsForm } from "./EventDetailsForm";
import { createTheme, ThemeProvider } from '@material-ui/core/styles';

const darkTheme = createTheme({
  palette: {
    mode: 'dark',
     primary: {
      contrastText: "#FFFFFF",
      dark: "#FFFFFF",
      main: "#FFFFFF",
      light: "#FFFFFF",
     },
  },
  overrides: {
      // Checkbox & Select arrow
      MuiSvgIcon: {
          root: {
              fill: "#FFFFFF",
          }
      },
      MuiFormLabel: {
        root: {
          color: '#FFF'
        }
      
      },
      MuiSelect: {
        root: {
          color: '#FFF',
          textAlign: 'left'
        }
      
      }
    }
});

export const EventDetails = (props) => {
    return (
        <section className="step-1 step--event-details">
            <header>
                <h2>Event Details</h2>
                <p>Please provide some basic event details so we can work out options.<br></br>Completing as much of this information as possible allows for a more accurate quote.</p>
            </header>
            <div className="form" id="step--event-details-form">
                <ThemeProvider theme={darkTheme}>
                    <EventDetailsForm />
                </ThemeProvider>
            </div>
        </section>
    )
}