import { configureStore } from '@reduxjs/toolkit';
import conditionalFieldsReducer from './features/conditionalFields/conditionalFieldsSlice';
import selectedOptionsReducer from './features/selectedOptions/selectedOptionsSlice';
import selectedTentsReducer from './features/selectedTents/selectedTentsSlice'
import sidebarSliceReducer from './features/sidebar/sidebarSlice';
import stepsSliceReducer from './features/steps/stepsSlice';
import tentsSliceReducer from './features/tents/tentsSlice'
import eventSliceReducer from './features/event/eventsSlice'
import formErrorsReducer from './features/formErrors/formErrorsSlice';

export const store = configureStore({
    reducer: {
        event: eventSliceReducer,
        sidebar: sidebarSliceReducer,
        steps: stepsSliceReducer,
        conditionalFields: conditionalFieldsReducer,
        tents: tentsSliceReducer,
        selectedTents: selectedTentsReducer,
        selectedOptions: selectedOptionsReducer,
        formErrors: formErrorsReducer
    },
})