import { useDispatch } from "react-redux";
import { removeTent } from "./selectedTentsSlice";

export const SelectedTent = (props) => {
    
    const dispatch = useDispatch();

    function numberWithCommas(x) {
        return (Math.round(x).toLocaleString('en'));
    }

    return (
        <div className="tent-item">
            <div className="tent-item--details">
                <h4>{props.selected.variation}</h4>
                <p><strong>{props.selected.size}</strong></p>
                <p className="title">Options:</p>
                
                <ul>
                { props.options.seating > 0 &&
                <li>Tables: { props.options.tables } { props.options.tableType ? 'Round' : 'Rectangular' }</li>
                }
                { props.options.seating > 0 &&
                <li>Seating: { props.options.seating } { props.options.seatingType ? 'Chairs' : 'Benches' }</li>
                }
                { props.options.seating < 1 &&
                <li>No Seating</li>
                }
                { props.options.woodenDanceFloor == 'yes' && props.selected.danceFloorPrice > 0 && 
                <li>Wooden Dance Floor</li>
                }
                { props.options.optionlighting == 'yes' &&
                <li>Lighting</li>
                }
                { props.options.mattedFlooring == 'yes' &&
                <li>Matted Flooring</li>
                }
                { props.options.cateringTents  == 'yes' &&
                <li>Catering Tent</li>
                }
                { props.options.optionStage  == 'yes' &&
                <li>Stage</li>
                }
                { props.options.rusticLogBar  == 'yes' &&
                <li>Rustic Log Bar</li>
                }
                { props.options.woodenFirePit  == 'yes' &&
                <li>Wooden Fire Pit</li>
                }
                { props.options.chillOutPack  == 'yes' &&
                <li>Chill Out Pack</li>
                }
                { props.options.rusticCakeTable  == 'yes' &&
                <li>Rustic Cake Table</li>
                }
                { props.options.woodenBarrel.length != 0 &&
                <li>Wooden Barrels: { props.options.woodenBarrel }</li>
                }
                </ul>
                
                <div className="tent-item--footer">
                    <div className="tent-item--price">&pound;{ numberWithCommas(props.selected.tentTotal.toFixed(2)) }
                    { props.selected.addDelivery &&
                    <small>Includes VAT, Delivery &amp; Insurance</small>
                    }
                    { !props.selected.addDelivery &&
                    <small>Includes VAT &amp; Insurance</small>
                    }
                    </div>
                    <div className="tent-item--remove">
                        <button onClick={() => dispatch(removeTent(props.selected))} className="button button--no-width"><img src="images/icon-delete-tent.svg" alt="" height="22" width="22" className="icon--svg"></img></button>
                    </div>
                </div>
            </div>
        </div>
    )
}