import tentList from "../../data/tents";
import postCodesList from "../../data/postcodes";
import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { activeStep, enabledSteps } from '../steps/stepsSlice';
import { useNavigate } from 'react-router-dom';
import { setInitialTents, updateByQuantity, increasePrice, decreasePrice, setInitialSeating, addShipping, addDeliveryNote } from "./tentsSlice";
import { Tent } from './Tent'
import { storeSelected } from "../selectedOptions/selectedOptionsSlice";
import Flickity from "react-flickity-component";


function filterTents(event, tents) {

	if ( event ) {
        let seated = event.seatedGuests;
        let guests = event.totalGuests;
		let filteredTents = [];

        // if seated 0 then ignore min seated
        if ( seated == ('' || 0) ) {
            tents.map(function(tent, i, el) {

                if ((tent.totalMin <= guests && tent.totalMax >= guests)) 
                {   
                    filteredTents.push(tent)
                }
            })  
        } else {
            tents.map(function(tent, i, el) {

                if ((tent.totalMin 	<= guests && tent.totalMax 	>= guests) &&
                    (tent.seatedMin <= seated && tent.seatedMax >= seated)) 
                { 
					filteredTents.push(tent)
                }
            })
        }

		return filteredTents;
		
	} else {
		return tents;
	}
}

function shippingLookUp(postcode, shippingData, season) {

    const remove = /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/g;
    const pcode  = postcode.replace(remove, '');
    
    let pcodeLookup     = shippingData.find( e => e.short == pcode.substring(0,2) );
    let pcodeLookupAlt  = shippingData.find( e => e.short == pcode.substring(0,1) );
    
    if ( pcodeLookup !== undefined ) {
        return ( season === 'high' ? pcodeLookup.high : pcodeLookup.low )
    } else if ( pcodeLookupAlt !== undefined && Number.isInteger( parseInt(pcode.substring(1,2)) ) ) {
        return ( season === 'high' ? pcodeLookupAlt.high : pcodeLookupAlt.low )
    } else {
        return -1;
    }
}

export const TentList = (props) => {

    const eventStore = useSelector((state) => state.event)
    const selectedOptionsStore = useSelector((state) => state.selectedOptions)
    const tentsFiltered = filterTents( eventStore, tentList );
    const showTipi = (tentsFiltered.find( e => e.type == "Tipi") ? true : null)
	const showSailcloth = (tentsFiltered.find( e => e.type == "Sailcloth") ? true : null)
    const showStretch = (tentsFiltered.find( e => e.type == "Stretch") ? true : null)

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const buildQuote = (e) => {
        e.preventDefault();
        dispatch(activeStep(3))
        dispatch(enabledSteps([1,2,3]))
        navigate("/quote-details")
    }

    // Run once to load event data
    useEffect( ()=> {

        let data = {
            seated: ( eventStore.seatedGuests ? eventStore.seatedGuests : '' ),
            guests: ( eventStore.totalGuests ? eventStore.totalGuests : '' ),
            addDelivery: false,
            hasResultsTipi: showTipi,
	        hasResultsSailCloth: showSailcloth,
	        hasResultsStretch: showStretch,
            tents: tentsFiltered,
        }

        dispatch(setInitialTents(data))


        // set seating & tables
        let seatingTypeVal = ( selectedOptionsStore ? selectedOptionsStore.seatingType : false ); // 
        let seatingVal = ( selectedOptionsStore && parseInt(selectedOptionsStore.seating) > 0 ? selectedOptionsStore.seating : Math.ceil(eventStore.seatedGuests / 6) * 2 ); // default: benches
        let tableTypeVal = ( selectedOptionsStore ? selectedOptionsStore.tableType : false ); // 
        let tablesVal =  ( selectedOptionsStore && parseInt(selectedOptionsStore.tables) > 0 ? selectedOptionsStore.tables : Math.ceil(eventStore.seatedGuests / 6) ); // default: rectangular

        let seatingData = {
            seatingType: seatingTypeVal,
            seating: seatingVal,
            tableType: tableTypeVal,
            tables: tablesVal
        }

        dispatch( setInitialSeating(seatingData) )
        

        if ( selectedOptionsStore !== undefined ) {

            let storeSeatingData = {
                fieldID: 'seating',
                value: seatingVal
            }

            dispatch( storeSelected(storeSeatingData) )
            
            let storeTablesData = {
                fieldID: 'tables',
                value: tablesVal
            }

            dispatch( storeSelected(storeTablesData) )
        
            if ( selectedOptionsStore.woodenBarrel > 0 ) {

                let data = {
                    fieldID: 'woodenBarrel',
                    value: selectedOptionsStore.woodenBarrel,
                    prev: 0
                }

                dispatch( updateByQuantity(data) );
            }

            const enabledOptions = ["woodenDanceFloor","optionlighting","mattedFlooring"];

            enabledOptions.map( (option) => {
                if ( selectedOptionsStore[option] != 'yes' ) {
                    dispatch( decreasePrice(option) );
                }
            })

            const disabledOptions = [ 'mirrorBallSpotLights','cateringTents','optionStage','rusticLogBar','woodenFirePit','chillOutPack','rusticCakeTable'];

            disabledOptions.map( (option) => {
                if ( selectedOptionsStore[option] == 'yes' ) {
                    dispatch( increasePrice(option) );
                }
            })  
        }  

        if ( eventStore.postcode.length > 0 ) {

            let pcode = eventStore.postcode.toUpperCase();

            if ( eventStore.eventDate.length ) {

                let eDateMonth = new Date(eventStore.eventDate).getMonth();

                // May - Sept (High) (Jan is 0)
                if (eDateMonth >= 4 && eDateMonth <= 8) {
                    
                    let shippingCost = shippingLookUp(pcode, postCodesList, 'high')
                    dispatch( addShipping( shippingCost) );
                    // set addDelivery
                    if ( shippingCost > 0  ) {
                        dispatch( addDeliveryNote(true))    
                    } else {
                        dispatch( addDeliveryNote(false))    
                    } 

                // Oct - April (Low)
                } else {

                    let shippingCost = shippingLookUp(pcode, postCodesList, 'low')
                    dispatch( addShipping( shippingCost) );

                    // set addDelivery
                    if ( shippingCost >= 0 ) {
                        dispatch( addDeliveryNote(true))    
                    } else {
                        dispatch( addDeliveryNote(false))    
                    }
                }
            
            // If no Event Date provided set to High
            } else {
                let shippingCost = shippingLookUp(pcode, postCodesList, 'high')
                dispatch( addShipping( shippingCost) );
                // set addDelivery
                if ( shippingCost > 0 ) {
                    dispatch( addDeliveryNote(true))    
                } else {
                    dispatch( addDeliveryNote(false))    
                }
            }            
        }
    
    }, [])

    const sliderOptions = {
        groupCells: true,
        lazyload: 1,
        freeScroll: true,
        contain: true,
        prevNextButtons: true,
        pageDots: false,
        cellAlign: 'left'
    }

    function buildTentList(tentArray, tentType) {
        let newTentList = [];
        tentArray.forEach((tent) => {
            if ( tent.type === tentType ) {
                newTentList.push(tent);
            }
        })
        return newTentList
    }
    
    function getRecommended(number, option, tentArray) {
        let current = tentArray[0][option];
        let diff = Math.abs(number - current)

        for (let i=0; i < tentArray.length; i++) {
            let newDiff = Math.abs(number - tentArray[i][option]);
            if (newDiff < diff) {
                diff = newDiff;
                current = tentArray[i][option];
            }
        }
        return current;
    }

    let tentStore = useSelector((state) => state.tents)

    let tentsTipi = (tentStore.tents.length > 0 ? buildTentList(tentStore.tents, 'Tipi' ) : [] );
    let tentsSailcloth = (tentStore.tents.length > 0 ? buildTentList(tentStore.tents, 'Sailcloth' ) : [] );
    let tentsStretch = (tentStore.tents.length > 0 ? buildTentList(tentStore.tents, 'Stretch' ) : [] );

    let ideal = ( eventStore.seatedGuests > 0 ? 'idealSeated' : 'idealTotal' )
    let idealVal = ( eventStore.seatedGuests > 0 ? eventStore.seatedGuests : eventStore.totalGuests )

    let tipiRecommended = (tentsTipi.length > 0 ? getRecommended(idealVal, ideal, tentsTipi) : ''); 
    let sailclothRecommended = (tentsSailcloth.length > 0 ? getRecommended(idealVal, ideal, tentsSailcloth) : '' );
    let stretchRecommended = (tentsStretch.length > 0 ? getRecommended(idealVal, ideal, tentsStretch) : '' );

    return (
        
        <>

            <button type="button" onClick={ buildQuote } className="proceed button button--icon-right button--c-white button--no-width" href="/">Proceed <img src="/images/icon-arrow-right-dark.svg" alt="" className="icon--svg"></img></button>
        
           { eventStore.filterTentsTipi !== 0 && tentStore.hasResultsTipi &&
            <div className="tent-type tent-type--c-pink">
                <h2>Tipis</h2>
                <Flickity reloadOnUpdate={true} options={sliderOptions}>
                    { tentsTipi.map((tent) => {
                        return <Tent key={tent.id} id={tent.id}
                        recommended={ ( tipiRecommended === tent[ideal] ? 1 : 0 ) }
                        seated={tentStore.seated} addDelivery={tentStore.addDelivery} {...tent} /> 
                    })}
                </Flickity>
            </div>
            }

            { eventStore.filterTentsSailCloth !== 0 && tentStore.hasResultsSailCloth &&
            <div className="tent-type tent-type--c-blue">
                <h2>Sailcloth</h2>
                <Flickity reloadOnUpdate={true} options={sliderOptions}>
                    { tentsSailcloth.map((tent) => {
                        return <Tent key={tent.id} id={tent.id}
                        recommended={ ( sailclothRecommended === tent[ideal] ? 1 : 0 ) }
                        seated={tentStore.seated} addDelivery={tentStore.addDelivery} {...tent} /> 
                    })}
                </Flickity>
            </div>
            }

            { eventStore.filterTentsStretch !== 0 && tentStore.hasResultsStretch &&
            <div className="tent-type tent-type--c-green">
                <h2>Stretch</h2>
                <Flickity reloadOnUpdate={true} options={sliderOptions}>
                    { tentsStretch.map((tent) => {
                        return <Tent key={tent.id} id={tent.id}
                        recommended={ ( stretchRecommended === tent[ideal] ? 1 : 0 ) }
                        seated={tentStore.seated} addDelivery={tentStore.addDelivery} {...tent} /> 
                    })}
                </Flickity>
            </div>
            }

            { ( 
                ( !tentStore.hasResultsTipi || eventStore.filterTentsTipi === 0 ) && 
                ( !tentStore.hasResultsSailCloth || eventStore.filterTentsSailCloth === 0 ) && 
                ( !tentStore.hasResultsStretch || eventStore.filterTentsStretch === 0 ) &&
                <div className="no-results">
                    <h3>No Results</h3>
                    <p>Please <a href="https://www.eventsundercanvas.co.uk/contact/">contact us</a> as your needs do not fit in our standard tent types.  Our team will be happy to help suggest some suitable options for you. Or feel free to adjust your tent filters to see other options and prices (if you are not having a formal seated dinner for all please state zero here).</p>
                </div>
            ) }
       </>
    )
}