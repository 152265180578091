import optionsList from '../../data/options'
import { createSlice } from '@reduxjs/toolkit'

const initialState = {
	seated: '',
	guests: '',
	chairsOnly: '',
	addDelivery: '',
	hasResultsTipi: '',
	hasResultsSailCloth: '',
	hasResultsStretch: '',
	tents: '',
	//optionsChanged: false
}

export const tentsSlice = createSlice({
  name: 'tents',
  initialState,
  reducers: {
	setInitialTents: (state, action) => {
		return {...action.payload}
	},
	setInitialSeating: (state, action) => {
		
		let seatingValue = action.payload.seating
		let tableValue = action.payload.tables
		let seatingType = action.payload.seatingType
		let tableType = action.payload.tableType

		let seatingOptionPrice;
		let tableOptionPrice;

		if ( seatingType == false ) {
			seatingOptionPrice = optionsList.find( e => e.optionID == 'benches').optionPrice	
		} else {
			seatingOptionPrice = optionsList.find( e => e.optionID == 'chairs').optionPrice	
		}

		if ( tableType == false ) {
			tableOptionPrice = optionsList.find( e => e.optionID == 'rectTables').optionPrice
		} else {
			tableOptionPrice = optionsList.find( e => e.optionID == 'roundTables').optionPrice
		}

		state.tents.map( (tent) => {
			tent.tentTotal += seatingValue * seatingOptionPrice
			tent.tentTotal += tableValue * tableOptionPrice
		})
	},
    increasePrice: (state, action) => {
		let oID = action.payload
		let specialOptions = ["woodenDanceFloor","optionlighting","mattedFlooring"];
		if ( !specialOptions.includes(oID) ) {
			let oValue = optionsList.find( e => e.optionID == oID).optionPrice
			state.tents.map( (tent) => {
				tent.tentTotal += oValue
			})
		} else {
			if (oID === "woodenDanceFloor") {
				state.tents.map( (tent) => {
					tent.tentTotal += tent.danceFloorPrice
				})
			}
			if (oID === "optionlighting") {
				state.tents.map( (tent) => {
					tent.tentTotal += tent.lightingPrice
				})
			}
			if (oID === "mattedFlooring") {
				state.tents.map( (tent) => {
					if (tent.type !== 'Stretch') {
						tent.tentTotal += tent.flooringPrice
					}
				})
			}
			// if (oID === "mirrorBallSpotLights") {
			// 	state.tents.map( (tent) => {
			// 		if (tent.type !== 'Stretch') {
			// 			tent.tentTotal += tent.mirrorBallSpotLightsPrice
			// 		}
			// 	})
			// }
		} 
    },
    decreasePrice: (state, action) => {
		let oID = action.payload
		let specialOptions = ["woodenDanceFloor","optionlighting","mattedFlooring"];
		if ( !specialOptions.includes(oID) ) {
			let oValue = optionsList.find( e => e.optionID == oID).optionPrice
			state.tents.map( (tent) => {
				tent.tentTotal -= oValue
			})
		} else {
			if (oID === "woodenDanceFloor") {
				state.tents.map( (tent) => {
					tent.tentTotal -= tent.danceFloorPrice
				})
			}
			if (oID === "optionlighting") {
				state.tents.map( (tent) => {
					tent.tentTotal -= tent.lightingPrice
				})
			}
			if (oID === "mattedFlooring") {
				state.tents.map( (tent) => {
					if (tent.type !== 'Stretch') {
						tent.tentTotal -= tent.flooringPrice
					}
				})
			}
			// if (oID === "mirrorBallSpotLights") {
			// 	state.tents.map( (tent) => {
			// 		if (tent.type !== 'Stretch') {
			// 			tent.tentTotal -= tent.mirrorBallSpotLightsPrice
			// 		}
			// 	})
			// }
		}
	},
	addShipping: (state, action) => {
		let shipping = action.payload
		
		state.tents.map( (tent) => {
			tent.tentTotal += ( shipping + ( shipping * 0.2 ) )
		})
	},
	updateByQuantity: (state, action) => {
		let qty = action.payload.value
		let prevqty = action.payload.prev
		let field = action.payload.fieldID
		let oValue = optionsList.find( e => e.optionID == field).optionPrice

		if (qty > prevqty) {
			let actualqty = qty - prevqty
			let amount = actualqty * oValue
			state.tents.map( (tent) => {
				tent.tentTotal += amount
			})
		} else if ( (qty < prevqty) || (qty == 0 && prevqty > 0) ) {
			let actualqty = prevqty - qty
			let amount = actualqty * oValue
			state.tents.map( (tent) => {
				tent.tentTotal -= amount
			})
		}
	},
	updateSeating: (state, action) => {
		let qty = action.payload.value
		let prevqty = action.payload.prev
		let oValue = action.payload.type ? optionsList.find( e => e.optionID == 'chairs').optionPrice : optionsList.find( e => e.optionID == 'benches').optionPrice

		if (qty < 1000 && prevqty < 1000) {
			if (qty > prevqty) {
				let actualqty = qty - prevqty
				let amount = actualqty * oValue
				state.tents.map( (tent) => {
					tent.tentTotal += amount
				})
			} else if ( (qty < prevqty) || (qty == 0 && prevqty > 0) ) {
				let actualqty = prevqty - qty
				let amount = actualqty * oValue
				state.tents.map( (tent) => {
					tent.tentTotal -= amount
				})
			}
		}
	},
	updateTables: (state, action) => {
		let qty = action.payload.value
		let prevqty = action.payload.prev
		let oValue = action.payload.type ? optionsList.find( e => e.optionID == 'roundTables').optionPrice : optionsList.find( e => e.optionID == 'rectTables').optionPrice 

		if (qty < 1000 && prevqty < 1000) {
			if (qty > prevqty) {
				let actualqty = qty - prevqty
				let amount = actualqty * oValue
				state.tents.map( (tent) => {
					tent.tentTotal += amount
				})
			} else if ( (qty < prevqty) || (qty == 0 && prevqty > 0) ) {
				let actualqty = prevqty - qty
				let amount = actualqty * oValue
				state.tents.map( (tent) => {
					tent.tentTotal -= amount
				})
			}
		}
	}, 
	switchTables: (state, action) => {
		let prevTablesValue = action.payload.prevValue;
		let prevTablesType = action.payload.prevType;
		let newTablesValue = action.payload.newValue;
		let newTablesType = action.payload.newType;
		let roundPrice = optionsList.find( e => e.optionID == 'roundTables').optionPrice
		let rectPrice = optionsList.find( e => e.optionID == 'rectTables').optionPrice

		let oldPrice = prevTablesType ? roundPrice : rectPrice
		let oldTotal = oldPrice * prevTablesValue;

		let newPrice = newTablesType ? roundPrice : rectPrice
		let newTotal = newPrice * newTablesValue;

		state.tents.map( (tent) => {
			tent.tentTotal -= oldTotal
			tent.tentTotal += newTotal
		})
	},
	switchSeating: (state, action) => {
		let prevSeatingValue = action.payload.prevValue;
		let prevSeatingType = action.payload.prevType;
		let newSeatingValue = action.payload.newValue;
		let newSeatingType = action.payload.newType;
		let chairsPrice = optionsList.find( e => e.optionID == 'chairs').optionPrice
		let benchesPrice = optionsList.find( e => e.optionID == 'benches').optionPrice

		let oldPrice = prevSeatingType ? chairsPrice : benchesPrice
		let oldTotal = oldPrice * prevSeatingValue;

		let newPrice = newSeatingType ? chairsPrice : benchesPrice
		let newTotal = newPrice * newSeatingValue;

		state.tents.map( (tent) => {
			tent.tentTotal -= oldTotal
			tent.tentTotal += newTotal
		})
	},
	setRecommended: (state, action) => {
		let tentID = action.payload.tentID;
		let tentType = action.payload.tentType;

		state[tentType].map( (tent) => {
			if (tent.id == tentID) {
				tent.recommended = 1;
			}
		})
	},
	addDeliveryNote: (state, action) => {
		state.addDelivery = action.payload
	},
	// optionsChanged: (state, action) => {
	// 	state.optionsChanged = action.payload
	// }
 }
})

// Action creators are generated for each case reducer function
export const { enableTentFilter, disableTentFilter, setInitialTents, addShipping, setInitialSeating, increasePrice, decreasePrice, updateByQuantity, updateSeating, updateTables, switchTables, switchSeating, setRecommended, addDeliveryNote } = tentsSlice.actions

export default tentsSlice.reducer
