import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    seatedGuests: '',
    totalGuests: '',
    postcode: '',
    eventDate: '',
	filterTentsTipi: 0,
	filterTentsSailCloth: 0,
	filterTentsStretch: 0
}

export const eventSlice = createSlice({
	name: 'event',
	initialState,
	reducers: {
		updateEvent: (state, action) => {
			return {...action.payload}
		},
		setInitialTentFilters: (state, action) => {
			state['filterTentsTipi'] = ( action.payload.filterTentsTipi[0] ? action.payload.filterTentsTipi[0] : 0 );
			state['filterTentsSailCloth'] = ( action.payload.filterTentsSailCloth[0] ? action.payload.filterTentsSailCloth[0] : 0 );
			state['filterTentsStretch'] = ( action.payload.filterTentsStretch[0] ? action.payload.filterTentsStretch[0] : 0);
		},
		enableTentFilter: (state, action) => {
			let filterID = action.payload;
			state[filterID] = 1;
		},
		
		disableTentFilter: (state, action) => {
			let filterID = action.payload;
			state[filterID] = 0;
		},
  	}
})

// Action creators are generated for each case reducer function
export const { updateEvent, setInitialTentFilters, enableTentFilter, disableTentFilter } = eventSlice.actions

export default eventSlice.reducer