// 1. Event data formik > event data store
// 2. Event data store > event bar
// 3. Event data store > filter tentlist
// 4. Tent > add to list > selectedTents store
// 5. selectedTents store > display in sidebar
// 6. Options > selectedOptions store
// 7. selectedTents store > display in quote sidebar
// 8. selectedOptions store > display in quote sidebar

import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { StepsBar } from "./features/steps/StepsBar";
import { HeaderGlobal } from './components/HeaderGlobal';
import { EventDetails } from './components/EventDetails';
import { EventOptions } from './components/EventOptions';
import { QuoteDetails } from './components/QuoteDetails';
import { ThankYou } from './components/ThankYou';
import Footer from './components/Footer';

function App() {

	return (
		<Router>
			<header className="header">
				<Routes>
					<Route path="/" element={<HeaderGlobal />} />
					<Route path="/quote-details" element={<HeaderGlobal />} />
					<Route path="*" element={<></>} />
				</Routes>
				<StepsBar />
			</header>
			<Routes>
				<Route path="/" exact element={<EventDetails />} />
				<Route path="/event-options" element={<EventOptions />} />
				<Route path="/quote-details" element={<QuoteDetails />} />
				<Route path="/thankyou" element={<ThankYou />} />		
			</Routes>
			<Routes>
				<Route path="/" exact element={<Footer className="footer footer--step-1" yellowText="Let's get started" /> } />
				<Route path="/event-options" element={<Footer className="footer footer--step-2" />} />
				<Route path="/quote-details" element={<Footer className="footer footer--step-3" />} />
				<Route path="/thankyou" element={<Footer className="footer footer--step-4" yellowText="Let's get started" />} />		
			</Routes>
		</Router>
  	);
}

export default App;
