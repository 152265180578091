const tentList = [
	{
		id: 1,
		type: 'Tipi',
		variation: 'Kung',
		size: '(8.25m diameter)',
		totalMin: 1,
		totalMax: 41,
		seatedMin: 6,
		seatedMax: 30,
		idealSeated: 11,
		idealTotal: 21,
		tentPrice: 1321.92,
		flooringPrice: 222.08,
		lightingPrice: 107.07,
		danceFloorPrice: 389.97,
		mirrorBallSpotLightsPrice: 169.21,
		tentTotal: 2041.05,
		photomain: "Kung.jpg", photo1: "", photo2: "", photo3: "",
	},
	{
		id: 2,
		type: 'Tipi',
		variation: 'Single Tipi',
		size: '(10.3m diameter)',
		totalMin: 21,
		totalMax: 91,
		seatedMin: 12,
		seatedMax: 70,
		idealSeated: 31,
		idealTotal: 81,
		tentPrice: 1815.33,
		flooringPrice: 277.60,
		lightingPrice: 107.08,
		danceFloorPrice: 389.97,
		mirrorBallSpotLightsPrice: 169.21,
		tentTotal: 2589.98,
		photomain: "Single_Tipi.jpg",
		photo1: "Single_Tipi_2.jpg",
		photo2: "Single_Tipi_3.jpg",
		photo3: ""
	},
	{
		id: 3,
		type: 'Tipi',
		variation: 'Tipi and Kung',
		size: '(10.3m x 18m)',
		totalMin: 31,
		totalMax: 121,
		seatedMin: 24,
		seatedMax: 70,
		idealSeated: 51,
		idealTotal: 81,
		tentPrice: 3137.25,
		flooringPrice: 499.69,
		lightingPrice: 214.15,
		danceFloorPrice: 389.97,
		mirrorBallSpotLightsPrice: 169.21,
		tentTotal: 4241.06,
		photomain: "Tipi_and_Kung.jpg",
		photo1: "Tipi_and_Kung.jpg",
		photo2: "",
		photo3: ""
	},
	{
		id: 4,
		type: 'Tipi',
		variation: 'Two Tipis',
		size: '(10.3m x 20m)',
		totalMin: 41,
		totalMax: 161,
		seatedMin: 40,
		seatedMax: 119,
		idealSeated: 61,
		idealTotal: 111,
		tentPrice: 3630.66,
		flooringPrice: 555.21,
		lightingPrice: 214.15,
		danceFloorPrice: 389.97,
		mirrorBallSpotLightsPrice: 169.21,
		tentTotal: 4789.99,
		photomain: "Two_Tipis.jpg",
		photo1: "Two_Tipis.jpg",
		photo2: "Two_Tipis_3.jpg",
		photo3: ""
	},
	{
		id: 5,
		type: 'Tipi',
		variation: 'Two Tipis and Kung',
		size: '(10.3m x 28m)',
		totalMin: 61,
		totalMax: 201,
		seatedMin: 50,
		seatedMax: 121,
		idealSeated: 91,
		idealTotal: 131,
		tentPrice: 4952.58,
		flooringPrice: 777.29,
		lightingPrice: 321.23,
		danceFloorPrice: 389.97,
		mirrorBallSpotLightsPrice: 169.21,
		tentTotal: 6441.07,
		photomain: "Two_Tipis_and_Kung.jpg",
		photo1: "Two_Tipis_and_Kung_2.jpg",
		photo2: "Two_Tipis_and_Kung_4.jpg",
		photo3: "Two_Tipis_and_Kung_5.jpg"
	},
	{
		id: 6,
		type: 'Tipi',
		variation: 'Three Tipis',
		size: '(20/20 / 30 x 10.3m)',
		totalMin: 71,
		totalMax: 241,
		seatedMin: 70,
		seatedMax: 140,
		idealSeated: 121,
		idealTotal: 151,
		tentPrice: 5445.99,
		flooringPrice: 832.81,
		lightingPrice: 321.23,
		danceFloorPrice: 389.97,
		mirrorBallSpotLightsPrice: 169.21,
		tentTotal: 6990.00,
		photomain: "Three_Tipis.jpg",
		photo1: "",
		photo2: "",
		photo3: ""
	},
	{
		id: 7,
		type: 'Tipi',
		variation: 'Three Tipis and Kung',
		size: '(28m x 20m)',
		totalMin: 110,
		totalMax: 281,
		seatedMin: 80,
		seatedMax: 160,
		idealSeated: 141,
		idealTotal: 181,
		tentPrice: 6767.91,
		flooringPrice: 1054.89,
		lightingPrice: 428.30,
		danceFloorPrice: 389.97,
		mirrorBallSpotLightsPrice: 169.21,
		tentTotal: 8641.07,
		photomain: "Three_Tipis_and_Kung.jpg",
		photo1: "Three_Tipis_and_Kung_2.jpg",
		photo2: "",
		photo3: ""
	},
	{
		id: 8,
		type: 'Tipi',
		variation: 'Four Tipis',
		size: '(20m x 30m)',
		totalMin: 141,
		totalMax: 301,
		seatedMin: 101,
		seatedMax: 210,
		idealSeated: 161,
		idealTotal: 201,
		tentPrice: 7261.32,
		flooringPrice: 1110.41,
		lightingPrice: 428.30,
		danceFloorPrice: 389.97,
		mirrorBallSpotLightsPrice: 169.21,
		tentTotal: 9190.00,
		photomain: "Four_Tipis_list.jpg",
		photo1: "Four_Tipis.jpg",
		photo2: "",
		photo3: ""
	},
	{
		id: 9,
		type: 'Tipi',
		variation: 'Four Tipis and Kung',
		size: '(28m x 30m)',
		totalMin: 161,
		totalMax: 301,
		seatedMin: 121,
		seatedMax: 230,
		idealSeated: 181,
		idealTotal: 241,
		tentPrice: 8583.24,
		flooringPrice: 1332.50,
		lightingPrice: 535.38,
		danceFloorPrice: 389.97,
		mirrorBallSpotLightsPrice: 169.21,
		tentTotal: 10841.08,
		photomain: "4_tipis_kung_list.jpg",
		photo1: "",
		photo2: "",
		photo3: ""
	},

	{
		id: 10,
		type: 'Sailcloth',
		variation: 'Small - One Pole',
		size: '(13.5m diameter)',
		totalMin: 31,
		totalMax: 141,
		seatedMin: 21,
		seatedMax: 101,
		idealSeated: 51,
		idealTotal: 81,
		tentPrice: 3259.98,
		flooringPrice: 499.69,
		lightingPrice: 89.20,
		danceFloorPrice: 389.97,
		mirrorBallSpotLightsPrice: 215.42,
		tentTotal: 4238.84,
		photomain: "Sailcloth_Small_one_pole.jpg",
		photo1: "Sailcloth_Small_one_pole_2.jpg",
		photo2: "Sailcloth_Small_one_pole_3.jpg",
		photo3: "Sailcloth_Small_one_pole_4.jpg"
	},
	{
		id: 11,
		type: 'Sailcloth',
		variation: 'Medium - Two Poles',
		size: '(19.6m x 13.5m)',
		totalMin: 51,
		totalMax: 201,
		seatedMin: 41,
		seatedMax: 151,
		idealSeated: 101,
		idealTotal: 151,
		tentPrice: 4811.03,
		flooringPrice: 888.34,
		lightingPrice: 151.69,
		danceFloorPrice: 389.97,
		mirrorBallSpotLightsPrice: 215.42,
		tentTotal: 6241.97,
		photomain: "Sailcloth_Medium_two_poles.jpg",
		photo1: "Sailcloth_Medium_two_poles_2.jpg",
		photo2: "Sailcloth_Medium_two_poles_3.jpg",
		photo3: ""
	},
	{
		id: 12,
		type: 'Sailcloth',
		variation: 'Large - Three Poles',
		size: '(25.7m x 13.5m)',
		totalMin: 71,
		totalMax: 301,
		seatedMin: 81,
		seatedMax: 201,
		idealSeated: 151,
		idealTotal: 201,
		tentPrice: 6082.40,
		flooringPrice: 1110.41,
		lightingPrice: 196.24,
		danceFloorPrice: 389.97,
		mirrorBallSpotLightsPrice: 215.42,
		tentTotal: 7779.02,
		photomain: "Sailcloth_Large_three_poles.jpg",
		photo1: "Sailcloth_Large_three_poles_2.jpg",
		photo2: "Sailcloth_Large_three_poles_3.jpg",
		photo3: "Sailcloth_Large_three_poles_4.jpg"
	},
	{
		id: 13,
		type: 'Sailcloth',
		variation: 'XLarge - Four Poles',
		size: '(31.8m x 13.5m)',
		totalMin: 121,
		totalMax: 301,
		seatedMin: 101,
		seatedMax: 251,
		idealSeated: 191,
		idealTotal: 251,
		tentPrice: 6926.52,
		flooringPrice: 1443.54,
		lightingPrice: 249.76,
		danceFloorPrice: 389.97,
		mirrorBallSpotLightsPrice: 215.42,
		tentTotal: 9009.78,
		photomain: "Sailcloth_xl_four_poles.jpg",
		photo1: "Sailcloth_xl_four_poles.jpg",
		photo2: "Sailcloth_xl_four_poles_2.jpg",
		photo3: "Sailcloth_xl_four_poles_list.jpg"
	},
	{
		id: 14,
		type: 'Sailcloth',
		variation: 'The Grand – Four Poles',
		size: '(18m x 18m)',
		totalMin: 121,
		totalMax: 301,
		seatedMin: 101,
		seatedMax: 251,
		idealSeated: 191,
		idealTotal: 251,
		tentPrice: 7444.52,
		flooringPrice: 1110.41,
		lightingPrice: 303.28,
		danceFloorPrice: 389.97,
		mirrorBallSpotLightsPrice: 215.42,
		tentTotal: 9248.18,
		photomain: "Big_apple_four_poles.jpg",
		photo1: "",
		photo2: "",
		photo3: ""
	},
	{
		id: 15,
		type: 'Sailcloth',
		variation: 'The Grand – Six Poles',
		size: '(24m x 18m)',
		totalMin: 151,
		totalMax: 301,
		seatedMin: 151,
		seatedMax: 301,
		idealSeated: 221,
		idealTotal: 291,
		tentPrice: 8654.35,
		flooringPrice: 1443.54,
		lightingPrice: 410.32,
		danceFloorPrice: 389.97,
		mirrorBallSpotLightsPrice: 215.42,
		tentTotal: 10898.18,
		photomain: "Grand_Sailcloth_6_poles.jpg",
		photo1: "",
		photo2: "",
		photo3: ""
	},

	{
		id: 16,
		type: 'Stretch',
		variation: 'Stretch Tent',
		size: '(10m x 6m)',
		totalMin: 1,
		totalMax: 61,
		seatedMin: 0,
		seatedMax: 61,
		idealSeated: 31,
		idealTotal: 31,
		tentPrice: 1285.90,
		flooringPrice: 166.56,
		lightingPrice: 62.46,
		danceFloorPrice: 0,
		tentTotal: 1348.36,
		photomain: "10x6_stretch_list.jpg",
		photo1: "10x6_stretch_tent.jpg",
		photo2: "10x6_stretch_fire.jpg",
		photo3: ""
	},
	{
		id: 17,
		type: 'Stretch',
		variation: 'Stretch Tent',
		size: '(12m x 10m)',
		totalMin: 21,
		totalMax: 121,
		seatedMin: 0,
		seatedMax: 101,
		idealSeated: 51,
		idealTotal: 71,
		tentPrice: 2122.34,
		flooringPrice: 333.12,
		lightingPrice: 124.92,
		danceFloorPrice: 389.97,
		tentTotal: 2637.23,
		photomain: "10x12m_stretch_tent.jpg",
		photo1: "",
		photo2: "",
		photo3: ""
	},
	{
		id: 18,
		type: 'Stretch',
		variation: 'Stretch Tent',
		size: '(15m x 10m)',
		totalMin: 31,
		totalMax: 151,
		seatedMin: 0,
		seatedMax: 121,
		idealSeated: 71,
		idealTotal: 81,
		tentPrice: 2554.61,
		flooringPrice: 444.17,
		lightingPrice: 89.23,
		danceFloorPrice: 389.97,
		tentTotal: 3033.81,
		photomain: "10x15m_stretch_tent.jpg",
		photo1: "",
		photo2: "",
		photo3: ""
	},
	{
		id: 19,
		type: 'Stretch',
		variation: 'Stretch Tent',
		size: '(21m x 10m)',
		totalMin: 61,
		totalMax: 211,
		seatedMin: 0,
		seatedMax: 181,
		idealSeated: 91,
		idealTotal: 101,
		tentPrice: 3510.03,
		flooringPrice: 610.73,
		lightingPrice: 151.69,
		danceFloorPrice: 389.97,
		tentTotal: 4051.69,
		photomain: "10x21_stretch_back.jpg",
		photo1: "10x21m_stretch_tent.jpg",
		photo2: "",
		photo3: ""
	}
]

export default tentList