const postCodesList = [
    { id:1, short:'AL',  high:250, low:150, },
	{ id:2, short:'BN',  high:300, low:150, },
	{ id:3, short:'BR',  high:250, low:150, },
	{ id:4, short:'CB',  high:200, low:100, },
	{ id:5, short:'CM',  high:150, low:0, },
	{ id:6, short:'CO',  high:100, low:0, },
	{ id:7, short:'CR',  high:300, low:150, },
	{ id:8, short:'CT',  high:300, low:150, },
	{ id:9, short:'DA',  high:250, low:150, },
	{ id:10, short:'DT', high:400, low:200, },
	{ id:11, short:'E',  high:250, low:150, },
	{ id:12, short:'EC', high:300, low:150, },
	{ id:13, short:'EN', high:250, low:150, },
	{ id:14, short:'GU', high:300, low:150, },
	{ id:15, short:'HA', high:300, low:150, },
	{ id:16, short:'HP', high:250, low:150, },
	{ id:17, short:'IG', high:250, low:150, },
	{ id:18, short:'IP', high:100, low:0, },
	{ id:19, short:'KT', high:300, low:150, },
	{ id:20, short:'LU', high:300, low:150, },
	{ id:21, short:'ME', high:300, low:150, },
	{ id:22, short:'MK', high:300, low:150, },
	{ id:23, short:'N',  high:250, low:150, },
	{ id:24, short:'NN', high:300, low:150, },
	{ id:25, short:'NR', high:250, low:150, },
	{ id:26, short:'NW', high:300, low:150, },
	{ id:27, short:'OX', high:300, low:150, },
	{ id:28, short:'PE', high:250, low:150, },
	{ id:29, short:'PO', high:400, low:200, },
	{ id:30, short:'RG', high:300, low:150, },
	{ id:31, short:'RM', high:200, low:100, },
	{ id:32, short:'SE', high:250, low:150, },
	{ id:33, short:'SG', high:250, low:150, },
	{ id:34, short:'SL', high:300, low:150, },
	{ id:35, short:'SM', high:250, low:150, },
	{ id:36, short:'SO', high:400, low:200, },
	{ id:37, short:'SS', high:200, low:100, },
	{ id:38, short:'SW', high:300, low:150, },
	{ id:39, short:'TN', high:300, low:150, },
	{ id:40, short:'TW', high:300, low:150, },
	{ id:41, short:'UB', high:300, low:150, },
	{ id:42, short:'W',  high:300, low:150, },
	{ id:43, short:'WC', high:400, low:200, },
	{ id:44, short:'WD', high:250, low:150, },
	{ id:45, short:'RH', high:300, low:150, }
]

export default postCodesList