export const ThankYou = (props) => {
    return (
        <section className="step-4 step--thankyou">
            <header>
                <h2>Thank you</h2>
                <p>A member of the team will be in touch soon.<br></br>A copy of your quote has been sent to your email address.</p>
            </header>
            <div className="button-group">
                <a className="button button--icon-right button--c-tri" href="https://eventsundercanvas.co.uk/">Back to Website <img src="images/icon-arrow-right-s.svg" alt=""></img></a>
                <a className="button button--icon-right button--c-sec" href="/">Start Another Quote <img src="images/icon-arrow-right-s.svg" alt=""></img></a>
            </div>
        </section>
    )
}