import { useSelector } from "react-redux";

export const EventData = () => {

    const eventStore = useSelector((state) => state.event)
    
    return (
        <header>
            <h2>Tent Selection &amp; Extras</h2>
            <div className="ed">
                <div className="ed--location"><img src="images/icon-location.svg" alt="" className="icon--svg"></img><span>Location:</span> {eventStore.postcode ? eventStore.postcode.toUpperCase() : 'Not provided'}</div>
                <div className="ed--guests"><img src="images/icon-guests.svg" alt="" className="icon--svg"></img><span>Guests:</span> {eventStore.totalGuests}</div>
                <div className="ed--seated"><img src="images/icon-seated.svg" alt="" className="icon--svg"></img><span>Seated:</span> {eventStore.seatedGuests ? eventStore.seatedGuests : "0"}</div>
            </div>
        </header>
    )
}