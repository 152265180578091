import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  //noSeated: false,
  sendBrochure: false,
}

export const conditionalFieldsSlice = createSlice({
  name: 'conditionalFields',
  initialState,
  reducers: {
    storeConditionalFields: (state, action) => {
      let id = action.payload.fieldID;
      let value = action.payload.value;
      state[id] = value;
    }
  },
})

// Action creators are generated for each case reducer function
export const { storeConditionalFields } = conditionalFieldsSlice.actions

export default conditionalFieldsSlice.reducer