import { useDispatch, useSelector } from "react-redux";
import { decreasePrice, increasePrice, updateByQuantity, updateSeating, updateTables, switchTables, switchSeating } from "../features/tents/tentsSlice";
import { storeSelected } from "../features/selectedOptions/selectedOptionsSlice";
//import { optionsChanged } from "../features/tents/tentsSlice";
import { useFormik } from "formik";
import * as Yup from "yup";
import { makeStyles } from '@material-ui/core/styles';
import Checkbox from '@material-ui/core/Checkbox';
import TextField from '@material-ui/core/TextField';
import Switch from '@material-ui/core/Switch';
import Stack from '@mui/material/Stack';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles(() => ({
    input: {
        color: "white",
    },
    numberinput: {
        '& input[type=number]': {
            '-moz-appearance': 'textfield'
        },
        '& input[type=number]::-webkit-outer-spin-button': {
            '-webkit-appearance': 'none',
            margin: 0
        },
        '& input[type=number]::-webkit-inner-spin-button': {
            '-webkit-appearance': 'none',
            margin: 0
        }
    }
}));

export const EventOptionsForm = (props) => {

    const classes = useStyles();
    const dispatch = useDispatch();
    const eventStore = useSelector((state) => state.event)
    const selectedOptionsStore = useSelector((state) => state.selectedOptions)
    const formik = useFormik({
        initialValues: {
            tables: (selectedOptionsStore !== undefined && selectedOptionsStore.tables !== "" ? selectedOptionsStore.tables : Math.ceil(eventStore.seatedGuests / 6) ), // default: rect.
            seating: (selectedOptionsStore !== undefined && selectedOptionsStore.seating !== "" ? selectedOptionsStore.seating : Math.ceil(eventStore.seatedGuests / 6) * 2 ), // default: benches
            woodenDanceFloor: (selectedOptionsStore !== undefined ? selectedOptionsStore.woodenDanceFloor : 'yes' ),
            optionlighting: (selectedOptionsStore !== undefined ? selectedOptionsStore.optionlighting : 'yes' ),
            mattedFlooring: (selectedOptionsStore !== undefined ? selectedOptionsStore.mattedFlooring : 'yes' ),
            mirrorBallSpotLights: (selectedOptionsStore !== undefined ? selectedOptionsStore.mirrorBallSpotLights : '' ),
            cateringTents: (selectedOptionsStore !== undefined ? selectedOptionsStore.cateringTents : '' ),
            optionStage: (selectedOptionsStore !== undefined ? selectedOptionsStore.optionStage : '' ),
            rusticLogBar: (selectedOptionsStore !== undefined ? selectedOptionsStore.rusticLogBar : '' ),
            woodenFirePit: (selectedOptionsStore !== undefined ? selectedOptionsStore.woodenFirePit : '' ),
            chillOutPack: (selectedOptionsStore !== undefined ? selectedOptionsStore.chillOutPack : '' ),
            rusticCakeTable: (selectedOptionsStore !== undefined ? selectedOptionsStore.rusticCakeTable : '' ),
            woodenBarrel: (selectedOptionsStore !== undefined ? selectedOptionsStore.woodenBarrel : '' ),
        },
        validationSchema: Yup.object({
            tables: Yup.number(),
            seating: Yup.number(),
            woodenBarrel: Yup.number(),
        }),
    });

    const handleChangeNumber = e => {
        let data = {
            fieldID: e.target.id,
            value: e.target.value,
            prev: selectedOptionsStore[e.target.id]
        }
        
        dispatch( updateByQuantity(data) )
    }

    const handleChangeTables = e => {
        let data = {
            fieldID: e.target.id,
            value: e.target.value,
            prev: selectedOptionsStore[e.target.id],
            type: selectedOptionsStore.tableType
        }
        
        dispatch( updateTables(data) )
    }

    const handleChangeSeating = e => {
        let data = {
            fieldID: e.target.id,
            value: e.target.value,
            prev: selectedOptionsStore[e.target.id],
            type: selectedOptionsStore.seatingType
        }

        dispatch( updateSeating(data) )
    }

    const handleChangeCheckbox = e => {
        e.target.checked ? dispatch( increasePrice(e.target.id) ) : dispatch( decreasePrice(e.target.id) )
        
        let storeCheckedData = { 
            fieldID: e.target.id,
            value: e.target.checked ? "yes" : ""
        }

        dispatch( storeSelected(storeCheckedData) )
    }

    return (
        
        <>
        <form>
            <div className="input-container">
                { eventStore.seatedGuests != ( ''|| 0 ) &&
                <div className="field-wrapper switches">
                    <p>Choose from our rustic wooden rectangular tables, or round tables.</p>
                    <div className="rows">
                        <Stack direction="row" spacing={1} alignItems="center">
                            <Typography>Rect.</Typography>
                            <Switch 
                                id="tableType"
                                checked={ selectedOptionsStore.tableType }
                                disabled={ eventStore.seatedGuests == '' ? true : false }
                                onChange={ e => { 

                                    let roundTable = Math.ceil(eventStore.seatedGuests / 10 );
                                    let rectTable = Math.ceil(eventStore.seatedGuests / 6 );

                                    let setTables = e.target.checked ? roundTable : rectTable

                                    formik.setFieldValue('tables', setTables)

                                    let data = {
                                        prevValue: document.getElementById('tables').value,
                                        prevType: selectedOptionsStore.tableType,
                                        newValue: document.getElementById('tableType').checked ? roundTable : rectTable,
                                        newType:  e.target.checked,
                                    }

                                    // switch tent total to be based on rect / round
                                    dispatch( switchTables(data) )  

                                    let storeData = { 
                                        fieldID: e.target.id,
                                        value: e.target.checked
                                    }
                                    
                                    // store which is currently checked rect / round
                                    dispatch( storeSelected(storeData) )

                                    let storeTablesData = {
                                        fieldID: 'tables',
                                        value: setTables
                                    }

                                    // store the value for the number of tables 
                                    dispatch( storeSelected(storeTablesData) )

                                    //let seatingSwitch = document.getElementById('seatingType');
                                    let chairs = eventStore.seatedGuests;
                                    let benches = Math.ceil(eventStore.seatedGuests / 6) * 2 ;
                                    let setSeating = e.target.checked ? chairs : benches

                                    let storeSeatingData = {
                                        fieldID: 'seating',
                                        value:  setSeating
                                    }

                                    // if table is Round
                                    if (e.target.checked) {
                                        
                                        let seatdata = {
                                            prevValue: document.getElementById('seating').value,
                                            prevType: selectedOptionsStore.seatingType,
                                            newValue: chairs,
                                            newType:  true,
                                        }

                                        // update the tent total price based on the previous and new seating values
                                        dispatch( switchSeating(seatdata) )
                                        
                                        // store the number of chairs
                                        dispatch( storeSelected(storeSeatingData) )

                                        let seatTypeData = { 
                                            fieldID: 'seatingType',
                                            value: true
                                        }
                                        
                                        // set stored seating type to Chairs
                                        dispatch( storeSelected(seatTypeData) )

                                        // set value of seating quantity 
                                        formik.setFieldValue('seating', chairs)
                                      
                                    // if table is switched back to Rectangle, reset seating back to default 'benches'
                                    } else {

                                        let seatdata = {
                                            prevValue: document.getElementById('seating').value, // number
                                            prevType: selectedOptionsStore.seatingType, // boolean
                                            newValue: benches, // number
                                            newType:  false, // boolean
                                        }

                                        // store seating checked as 'benches'
                                        dispatch( switchSeating(seatdata) ) 
                                        dispatch( storeSelected(storeSeatingData) )

                                        let seatTypeData = { 
                                            fieldID: 'seatingType',
                                            value: false
                                        }
    
                                        dispatch( storeSelected(seatTypeData) )

                                        formik.setFieldValue('seating', setSeating) 
                                    }

                                    //dispatch( optionsChanged(true) );

                                }}
                            />
                            <Typography>Round</Typography>
                        </Stack>
                        <label htmlFor="tables">Tables</label>
                        <TextField
                            InputProps={{ className: classes.input + ' ' + classes.numberinput, }}
                            InputLabelProps={{ className: classes.input }}
                            style={{width: "52px"}}
                            color="primary"
                            variant="filled"
                            id="tables"
                            name="tables"
                            type="number"
                            helperText="Qty"
                            onChange={ e => { 
                                formik.handleChange(e);
                                let fieldvalue = e.target.value
                                if (fieldvalue > 999) {
                                    formik.setFieldValue('tables', 999)
                                }
                                if (fieldvalue < 0) {
                                    formik.setFieldValue('tables', 0)
                                }
                                if (fieldvalue >= 0  && fieldvalue <= 999) {
                                    handleChangeTables(e); 
                                    let data = { 
                                        fieldID: e.target.id,
                                        value: fieldvalue
                                    }
                                    dispatch( storeSelected(data) );
                                }
                                //dispatch( optionsChanged(true) );
                            }}
                            onBlur={ formik.handleBlur }
                            value={ formik.values.tables }
                            error={ formik.touched.tables && Boolean(formik.errors.tables) }
                            disabled={ eventStore.seatedGuests == '' ? true : false }
                        />
                    </div>
                </div>
                }

                { eventStore.seatedGuests != ('' || 0) &&

                <div className="field-wrapper switches">
                    <p>Choose from rustic wooden benches, or chairs.</p>
                    <div className="rows">
                        <Stack direction="row" spacing={1} alignItems="center">
                            <Typography>Benches</Typography>
                            <Switch 
                                id="seatingType"
                                checked={ selectedOptionsStore.seatingType || selectedOptionsStore.tableType }
                                disabled={ eventStore.seatedGuests == '' ? true : false || selectedOptionsStore.tableType }
                                onChange={ e => { 

                                    let chairs = eventStore.seatedGuests;
                                    let benches = Math.ceil(eventStore.seatedGuests / 6) * 2;

                                    let setSeating = e.target.checked ? chairs : benches

                                    formik.setFieldValue('seating', setSeating)

                                    let data = {
                                        prevValue: document.getElementById('seating').value,
                                        prevType: selectedOptionsStore.seatingType,
                                        newValue: document.getElementById('seatingType').checked ? chairs : benches,
                                        newType:  e.target.checked,
                                    }

                                    dispatch( switchSeating(data) )  

                                    let storeData = { 
                                        fieldID: e.target.id,
                                        value: e.target.checked
                                    }

                                    dispatch( storeSelected(storeData) )

                                    let storeSeatingData = {
                                        fieldID: 'seating',
                                        value:  setSeating
                                    }

                                    dispatch( storeSelected(storeSeatingData) )

                                    //dispatch( optionsChanged(true) );
                                }}
                            />
                            <Typography>Chairs</Typography>
                        </Stack>
                        <label htmlFor="seating">Seating</label>
                        <TextField
                            InputProps={{ className: classes.input + ' ' + classes.numberinput }}
                            InputLabelProps={{ className: classes.input }}
                            style={{width: "52px"}}
                            color="primary"
                            variant="filled"
                            id="seating"
                            name="seating"
                            type="number"
                            helperText="Qty"
                            onChange={ e => { 
                                formik.handleChange(e);
                                let fieldvalue = e.target.value
                                if (fieldvalue > 999) {
                                    let data = { 
                                        fieldID: e.target.id,
                                        value: 999
                                    }
                                    formik.setFieldValue('seating', 999)
                                    dispatch( storeSelected(data) ); 
                                }
                                else if (fieldvalue < 0) {
                                    let data = { 
                                        fieldID: e.target.id,
                                        value: 0
                                    }
                                    formik.setFieldValue('seating', 0)
                                    dispatch( storeSelected(data) ); 
                                }
                                else if (fieldvalue >= 0  && fieldvalue <= 999) {
                                    let data = { 
                                        fieldID: e.target.id,
                                        value: fieldvalue
                                    }
                                    dispatch( storeSelected(data) );
                                }
                                handleChangeSeating(e);
                                //dispatch( optionsChanged(true) );
                            }}
                            onBlur={ formik.handleBlur }
                            value={ formik.values.seating }
                            error={ formik.touched.seating && Boolean(formik.errors.seating) }
                            disabled={ eventStore.seatedGuests == '' ? true : false }
                        />
                    </div>
                </div>
                }

                <div className="field-wrapper">
                    <label htmlFor="woodenDanceFloor">Wooden Dance Floor</label>
                    <Checkbox
                        color="primary"
                        id="woodenDanceFloor"
                        name="woodenDanceFloor"
                        onChange={ (e) => { 
                            handleChangeCheckbox(e); 
                            formik.handleChange(e);
                            //dispatch( optionsChanged(true) );
                        }}
                        onBlur={ formik.handleBlur }
                        selected={ formik.values.woodenDanceFloor }
                        value="yes"
                        checked={selectedOptionsStore && selectedOptionsStore.woodenDanceFloor == 'yes' ? true : false }
                    />
                </div>

                <div className="field-wrapper">
                    <label htmlFor="optionlighting">Lighting</label>
                    <Checkbox
                        color="primary"
                        id="optionlighting"
                        name="optionlighting"
                        onChange={ (e) => { 
                            handleChangeCheckbox(e);
                            formik.handleChange(e);
                            //dispatch( optionsChanged(true) );
                        }}
                        onBlur={ formik.handleBlur }
                        selected={ formik.values.optionlighting }
                        value="yes"
                        checked={ selectedOptionsStore && selectedOptionsStore.optionlighting == 'yes' ? true : false }
                    />
                </div>

                <div className="field-wrapper">
                    <label htmlFor="mattedFlooring">Matted Flooring</label>
                    <Checkbox
                        color="primary"
                        id="mattedFlooring"
                        name="mattedFlooring"
                        onChange={ (e) => { 
                            handleChangeCheckbox(e);
                            formik.handleChange(e);
                            //dispatch( optionsChanged(true) );
                        }}
                        onBlur={ formik.handleBlur }
                        value="yes"
                        checked={ selectedOptionsStore && selectedOptionsStore.mattedFlooring == 'yes' ? true : false }
                    />
                </div>

                <div className="field-wrapper">
                    <label htmlFor="cateringTents">Catering Tent</label>
                    <Checkbox
                        color="primary"
                        id="cateringTents"
                        name="cateringTents"
                        onChange={ (e) => { 
                            handleChangeCheckbox(e);
                            formik.handleChange(e);
                            //dispatch( optionsChanged(true) );
                        }}
                        onBlur={ formik.handleBlur }
                        value="yes"
                        checked={ selectedOptionsStore && selectedOptionsStore.cateringTents == 'yes' ? true : false }
                    />
                </div>

                <div className="field-wrapper">
                    <label htmlFor="optionStage">Stage</label>
                    <Checkbox
                        color="primary"
                        id="optionStage"
                        name="optionStage"
                        onChange={ (e) => { 
                            handleChangeCheckbox(e);
                            formik.handleChange(e);
                            //dispatch( optionsChanged(true) );
                        }}
                        onBlur={ formik.handleBlur }
                        value="yes"
                        checked={ selectedOptionsStore && selectedOptionsStore.optionStage == 'yes' ? true : false }
                    />
                </div>

                <div className="field-wrapper">
                    <label htmlFor="rusticLogBar">Rustic Log Bar</label>
                    <Checkbox
                        color="primary"
                        id="rusticLogBar"
                        name="rusticLogBar"
                        onChange={ (e) => { 
                            handleChangeCheckbox(e);
                            formik.handleChange(e);
                            //dispatch( optionsChanged(true) );
                        }}
                        onBlur={ formik.handleBlur }
                        value="yes"
                        checked={ selectedOptionsStore && selectedOptionsStore.rusticLogBar == 'yes' ? true : false }
                    />
                </div>

                <div className="field-wrapper">
                    <label htmlFor="woodenFirePit">Wooden Fire Pit</label>
                    <Checkbox
                        color="primary"
                        id="woodenFirePit"
                        name="woodenFirePit"
                        onChange={ (e) => { 
                            handleChangeCheckbox(e);
                            formik.handleChange(e);
                            //dispatch( optionsChanged(true) );
                        }}
                        onBlur={ formik.handleBlur }
                        value="yes"
                        checked={ selectedOptionsStore && selectedOptionsStore.woodenFirePit == 'yes' ? true : false }
                    />
                </div>

                <div className="field-wrapper">
                    <label htmlFor="chillOutPack">Chill Out Pack</label>
                    <Checkbox
                        color="primary"
                        id="chillOutPack"
                        name="chillOutPack"
                        onChange={ (e) => { 
                            handleChangeCheckbox(e);
                            formik.handleChange(e);
                            //dispatch( optionsChanged(true) );
                        }}
                        onBlur={ formik.handleBlur }
                        value="yes"
                        checked={ selectedOptionsStore && selectedOptionsStore.chillOutPack == 'yes' ? true : false }
                    />
                </div>

                <div className="field-wrapper">
                    <label htmlFor="rusticCakeTable">Rustic Cake Table</label>
                    <Checkbox
                        color="primary"
                        id="rusticCakeTable"
                        name="rusticCakeTable"
                        onChange={ (e) => { 
                            handleChangeCheckbox(e);
                            formik.handleChange(e);
                            //dispatch( optionsChanged(true) );
                        }}
                        onBlur={ formik.handleBlur }
                        value="yes"
                        checked={ selectedOptionsStore && selectedOptionsStore.rusticCakeTable == 'yes' ? true : false }
                    />
                </div>

                <div className="field-wrapper">
                    <label htmlFor="woodenBarrel">Wooden Barrel</label>
                    <TextField
                        InputProps={{ 
                            className: classes.input + ' ' + classes.numberinput,
                            inputProps: {
                                min:0,
                                max:999    
                            }
                        }}
                        InputLabelProps={{ className: classes.input }}
                        style={{width: "52px"}}
                        color="primary"
                        variant="filled"
                        id="woodenBarrel"
                        name="woodenBarrel"
                        type="number"
                        helperText="Qty"
                        onChange={ e => { 
                            formik.handleChange(e);
                            let fieldvalue = e.target.value
                            if (fieldvalue > 999) {
                                formik.setFieldValue('woodenBarrel', 999)
                            }
                            if (fieldvalue < 0) {
                                formik.setFieldValue('woodenBarrel', 0)
                            }
                            if (fieldvalue >= 0  && fieldvalue < 999) {
                                handleChangeNumber(e); 
                                let data = { 
                                    fieldID: e.target.id,
                                    value: fieldvalue
                                }
                                dispatch(storeSelected(data));
                            }
                            //dispatch( optionsChanged(true) );
                        }}
                        onBlur={ formik.handleBlur }
                        value={ formik.values.woodenBarrel }
                        error={ formik.touched.woodenBarrel && Boolean(formik.errors.woodenBarrel) }
                    />
                    
                </div>

            </div>
        </form>

    </>
    )
}