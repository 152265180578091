import { NavLink, useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from "react-redux";
import { activeStep, enabledSteps } from '../steps/stepsSlice';

export const StepsBar = (props) => {

    const steps = useSelector((state) => state.steps)
    const dispatch = useDispatch()

    window.addEventListener('popstate', (event) => {
        if ( steps.enabled.includes(2) && steps.active == 3 ) {
            dispatch(activeStep(2)); 
            dispatch(enabledSteps([1,2]))
        }
    });

    return (
        <div className={"steps" + (steps.active == 1 ? " step-1" : '') + (steps.active == 2 ? " step-2" : '') + (steps.active == 3 ? " step-3" : '' + (steps.active == 4 ? " step-4" : ''))}>
            { steps.enabled.includes(1) &&
             <NavLink to="/" onClick={ e => { dispatch(activeStep(1)); dispatch(enabledSteps([1])) }}>1</NavLink>
            }
            { !steps.enabled.includes(1) &&
            <a href="#" className="disabled">1</a>
            }
            { steps.enabled.includes(2) &&
            <NavLink to="/event-options" onClick={ e => { dispatch(activeStep(2)); dispatch(enabledSteps([1,2])) }} >2</NavLink>
            }
            { !steps.enabled.includes(2) &&
            <a href="#" className="disabled">2</a>
            }
            { steps.enabled.includes(3) &&
            <NavLink to="/quote-details" onClick={ e => { dispatch(activeStep(3)) }}>3</NavLink>
            }
            { !steps.enabled.includes(3) &&
            <a href="#" className="disabled">3</a>
            }
            { steps.enabled.includes(4) &&
            <NavLink to="/thankyou" onClick={ e => { dispatch(activeStep(4)) }}>4</NavLink>       
            }
            { !steps.enabled.includes(4) &&
            <a href="#" className="disabled">4</a>
            }
        </div>
    )
}