import React from 'react';

const Footer = ({ className, yellowText, ...props }) => {

    return (
        <footer className={className}>
            { yellowText &&
            <p className="yellow-text">{yellowText}</p> 
            }
            <a href="https://eventsundercanvas.co.uk/contact/">Contact Us</a> 
            <a href="mailto:info@eventsundercanvas.co.uk">info@eventsundercanvas.co.uk</a>
            <a href="tel:01206-298074">01206 298074</a>
        </footer>
    );
};

export default Footer;