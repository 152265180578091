import { React, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { enableTentFilter, disableTentFilter, setInitialTentFilters } from "../features/event/eventsSlice";
import { useFormik } from "formik";
import * as Yup from "yup";
import Checkbox from '@material-ui/core/Checkbox';

export const FilterTentsForm = (props) => {

    const dispatch = useDispatch();
    
    const eventStore = useSelector((state) => state.event)
    const formik = useFormik({
        initialValues: {
            filterTentsTipi: eventStore.filterTentsTipi,
            filterTentsSailCloth: eventStore.filterTentsSailCloth,
            filterTentsStretch: eventStore.filterTentsStretch
        },
        validationSchema: Yup.object({
            filterTentsTipi: Yup.string(),
            filterTentsSailCloth: Yup.string(),
            filterTentsStretch: Yup.string(),
        }),
    });

    const handleChangeCheckboxFilter = e => {
        e.target.checked ? dispatch( enableTentFilter(e.target.id) ) : dispatch( disableTentFilter(e.target.id) )
    }

    // Run once to load & store initial tent filter data
    useEffect( () => {
        let filters = {};
        filters.filterTentsTipi = eventStore.filterTentsTipi;
        filters.filterTentsSailCloth = eventStore.filterTentsSailCloth;
        filters.filterTentsStretch = eventStore.filterTentsStretch;
        dispatch( setInitialTentFilters( filters ) );
    }, [])

    return (
        
        <>
        <form>
            <div className="input-container">
               <div className="field-wrapper">
                    <label htmlFor="filterTentsTipi">Include Tipis</label>
                    <Checkbox
                        color="primary"
                        id="filterTentsTipi"
                        name="filterTentsTipi"
                        onChange={ (e) => { 
                            formik.handleChange(e);
                            handleChangeCheckboxFilter(e);
                        }}
                        onBlur={ formik.handleBlur }
                        checked={ formik.values && formik.values.filterTentsTipi[0] == '1' ? true : false }
                        value="1"
                    />
                </div>

                <div className="field-wrapper">
                    <label htmlFor="filterTentsSailCloth">Include Sailcloth Tents</label>
                    <Checkbox
                        color="primary"
                        id="filterTentsSailCloth"
                        name="filterTentsSailCloth"
                        onChange={ (e) => { 
                            formik.handleChange(e);
                            handleChangeCheckboxFilter(e);
                        }}
                        onBlur={ formik.handleBlur }
                        checked={ formik.values && formik.values.filterTentsSailCloth[0] == '1' ? true : false }
                        value="1"
                    />
                </div>

                <div className="field-wrapper">
                    <label htmlFor="filterTentsStretch">Include Stretch Tents</label>
                    <Checkbox
                        color="primary"
                        id="filterTentsStretch"
                        name="filterTentsStretch"
                        onChange={ (e) => { 
                            formik.handleChange(e);
                            handleChangeCheckboxFilter(e);
                        }}
                        onBlur={ formik.handleBlur }
                        checked={ formik.values && formik.values.filterTentsStretch[0] == '1' ? true : false }
                        value="1"
                    />
                </div>
            </div>
        </form>

    </>
    )
}