import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { activeStep, enabledSteps } from "../features/steps/stepsSlice";
import { storeConditionalFields } from "../features/conditionalFields/conditionalFieldsSlice";
import { formErrorsResponse } from "../features/formErrors/formErrorsSlice"
import FormErrorsDisplay from "../features/formErrors/FormErrorsDisplay";
import { useFormik } from "formik";
import axios from "axios";
import * as Yup from "yup";
import MenuItem from '@material-ui/core/MenuItem';
import Checkbox from '@material-ui/core/Checkbox';
import TextField from '@material-ui/core/TextField';
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel"
import FormControl from "@material-ui/core/FormControl"
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
    input: {
        color: "white",
    },
    numberinput: {
        '& input[type=number]': {
            '-moz-appearance': 'textfield'
        },
        '& input[type=number]::-webkit-outer-spin-button': {
            '-webkit-appearance': 'none',
            margin: 0
        },
        '& input[type=number]::-webkit-inner-spin-button': {
            '-webkit-appearance': 'none',
            margin: 0
        }
    }
}));

export const EmailForm = (props) => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const classes  = useStyles();

    const event = useSelector((state) => state.event)
    const selection = useSelector((state) => state.selectedTents)
    const errorDisplay = useSelector((state) => state.formErrors.errors)

    const formik = useFormik({
        initialValues: {
            personName: "",
            personEmail: "",
            confirmEmail: "",
            eventType: "",
            personPhone: "",
            personComments: "",
            sendBrochure: "",
            personAddress: ""
        },
        validationSchema: Yup.object({
            personName: Yup.string().required('Please enter your name.'),
            personEmail: Yup.string().email().required('Please enter your email.'),
            confirmEmail: Yup.string().email().required('Please confirm your email.'),
            eventType: Yup.string().required('Please choose an event type.'),
            //eventDate: Yup.date().nullable(),
            personPhone: Yup.string(),
            personComments: Yup.string().nullable(),
            sendBrochure: Yup.boolean(),
            personAddress: Yup.string().when("sendBrochure", {
                is: true,
                then: Yup.string().required("Please enter the address where you would like us to send a brochure to.")
            })
        }),
        onSubmit: (values) => {
            
            let postData = {
                contact: {
                    ...values
                },
                event: {
                    ...event
                },
                tents: {
                    ...selection
                }
            }

            axios.post('/api/mailer.php', postData)
            .then(function (response) {
                if (response.status == 200) {
                    dispatch(activeStep(4))
                    dispatch(enabledSteps([4]))
                    navigate("/thankyou")
                }
            })
            .catch(function (error) {

                if (error.response) {
                    // The request was made and the server responded with a status code
                    // that falls out of the range of 2xx
                    // console.log(error.response.data);
                    // console.log(error.response.status);
                    // console.log(error.response.headers);
                    if ( error.response.status == 400 ) {
                        dispatch(formErrorsResponse(error.response.data))
                    }
                } else if (error.request) {
                    // The request was made but no response was received
                    // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                    // http.ClientRequest in node.js
                    console.log(error.request);
                } else {
                    // Something happened in setting up the request that triggered an Error
                    console.log('Error', error.message);
                }
            });
        },
    });

    let conditionalFields = useSelector((state) => state.conditionalFields)

    return (
        <form onSubmit={ formik.handleSubmit }>
            { errorDisplay.length > 0 &&
            <FormErrorsDisplay errors={errorDisplay} />
            }
            <div className="input-container">
                <div className="field-wrapper">
                    <TextField 
                        InputProps={{ className: classes.input }}
                        InputLabelProps={{ className: classes.input }}
                        style={{width: "300px"}}
                        variant="filled"
                        id="personName"
                        name="personName"
                        type="text"
                        label="Name"
                        error={formik.touched.personName && Boolean(formik.errors.personName)}
                        helperText={formik.touched.personName && formik.errors.personName}
                        onChange={ formik.handleChange }
                        onBlur={ formik.handleBlur }
                        value={ formik.values.personName }
                   />
                </div>

                <div className="field-wrapper">
                    <TextField
                        InputProps={{ className: classes.input }}
                        InputLabelProps={{ className: classes.input }}
                        style={{width: "300px"}}
                        color="primary"
                        variant="filled"
                        id="personEmail"
                        name="personEmail"
                        type="email"
                        label="Email"
                        error={formik.touched.personEmail && Boolean(formik.errors.personEmail)}
                        helperText={formik.touched.personEmail && formik.errors.personEmail}
                        onChange={ formik.handleChange }
                        onBlur={ formik.handleBlur }
                        value={ formik.values.personEmail }
                    />
                </div>

                <div className="field-wrapper">
                    <TextField
                        InputProps={{ className: classes.input }}
                        InputLabelProps={{ className: classes.input }}
                        style={{width: "300px"}}
                        color="primary"
                        variant="filled"
                        id="confirmEmail"
                        name="confirmEmail"
                        type="email"
                        label="Confirm Email"
                        error={formik.touched.confirmEmail && Boolean(formik.errors.confirmEmail)}
                        helperText={formik.touched.confirmEmail && formik.errors.confirmEmail}
                        onChange={ formik.handleChange }
                        onBlur={ formik.handleBlur }
                        value={ formik.values.confirmEmail }
                    />
                </div>

                <div className="field-wrapper">
                    <FormControl variant="filled" sx={{ m: 1, minWidth: 120 }}>
                        <InputLabel id="eventType-label">Event Type</InputLabel>
                        <Select
                            style={{width: "300px"}}
                            labelId="eventType-label"
                            id="eventType"
                            name="eventType"
                            color="primary"
                            label="Event Type"
                            //value=""
                            error={formik.touched.eventType && Boolean(formik.errors.eventType)}
                            //helperText={formik.touched.eventType && formik.errors.eventType}
                            onChange= { formik.handleChange }
                        >   
                            <MenuItem value={ 'Wedding' }>Wedding</MenuItem>
                            <MenuItem value={ 'Party' }>Party</MenuItem>
                            <MenuItem value={ 'Festival' }>Festival</MenuItem>
                            <MenuItem value={ 'Corporate' }>Corporate</MenuItem>
                            <MenuItem value={ 'Other' }>Other</MenuItem>
                        </Select>
                    </FormControl>
                </div>

                <div className="field-wrapper">
                    <TextField
                        InputProps={{ className: classes.input }}
                        InputLabelProps={{ className: classes.input }}
                        style={{width: "300px"}}
                        color="primary"
                        variant="filled" 
                        id="personPhone"
                        name="personPhone"
                        type="text"
                        label="Telephone Number"
                        error={formik.touched.personPhone && Boolean(formik.errors.personPhone)}
                        helperText={formik.touched.personPhone && formik.errors.personPhone}
                        onChange={ formik.handleChange }
                        onBlur={ formik.handleBlur }
                        value={ formik.values.personPhone }
                   />
                </div>

                <div className="field-wrapper">
                    <TextField
                        InputProps={{ className: classes.input }}
                        InputLabelProps={{ className: classes.input }}
                        style={{width: "300px"}}
                        color="primary"
                        variant="filled"
                        id="personComments"
                        multiline
                        minRows={4}
                        //defaultValue="Default Value"
                        label="Comments / Notes"
                        error={formik.touched.personComments && Boolean(formik.errors.personComments)}
                        helperText={formik.touched.personComments && formik.errors.personComments}
                        onChange={ formik.handleChange }
                        onBlur={ formik.handleBlur }
                        value={ formik.values.personComments }
                    />
                </div>

                <div className="field-wrapper">
                    <label htmlFor="sendBrochure">Send Brochure</label>
                        <Checkbox
                            color="primary"
                            id="sendBrochure"
                            name="sendBrochure"
                            onChange={ e => { 
                                formik.handleChange(e)
                                let storeData = { 
                                    fieldID: e.target.id,
                                    value: e.target.checked
                                }
                                dispatch(storeConditionalFields(storeData))
                                
                            }}
                            onBlur={ formik.handleBlur }
                            //value={ formik.values.sendBrochure }
                        />
                </div>
                
                { conditionalFields.sendBrochure !== false && 
                <div className="field-wrapper">
                    <TextField
                        InputProps={{ className: classes.input }}
                        InputLabelProps={{ className: classes.input }}
                        style={{width: "300px"}}
                        color="primary"
                        variant="filled"
                        id="personAddress"
                        label="Postal Address"
                        error={formik.touched.personAddress && Boolean(formik.errors.personAddress)}
                        helperText={formik.touched.personAddress && formik.errors.personAddress}
                        onChange={ formik.handleChange }
                        onBlur={ formik.handleBlur }
                        value={ formik.values.personAddress }
                        multiline
                        minRows={4}
                    />
                </div>
                }
                <button className="button button--large button--c-sec" type="submit">Let's start talking <img src="images/icon-arrow-right-s.svg" alt="" /></button> 
            </div>
        </form>
    )
}