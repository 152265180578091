import { useSelector } from "react-redux";
import { Fancybox } from "@fancyapps/ui";
import ShortlistButton from "../../components/ShortlistButton";

export const Tent = (props) => {
    
    const selectedOptions   = useSelector((state) => state.selectedOptions)
    let tentSelection = {}
    tentSelection.selected = props;

    tentSelection.options = selectedOptions;

    let tentPhotos = [];

    if ( props.photo1.length > 0 ) {
        tentPhotos.push(
            {
                src: "/images/tents/" + props.photo1,
                type: "image"
            }
        )
    }
    if ( props.photo2.length > 0 ) {
        tentPhotos.push(
            {
                src: "/images/tents/" + props.photo2,
                type: "image"
            }
        )
    }
    if ( props.photo3.length > 0 ) {
        tentPhotos.push(
            {
                src: "/images/tents/" + props.photo3,
                type: "image"
            }
        )
    }

    function initFancyBox() {
        if ( tentPhotos.length > 0 ) {
            new Fancybox(tentPhotos);
        }
    }

    function numberWithCommas(x) {
        return (Math.round(x).toLocaleString('en'));
    }

    return (
        <div className={ props.recommended > 0 ? "tent-item tent-item--recommended" : "tent-item" }> 
            <div className={ tentPhotos.length > 0 ? "has-photo tent-item--gallery" : "tent-item--gallery"}>
                <a onClick={initFancyBox}>
                   <img 
                        sizes="(max-width:767.98px) 141px, 215px"
                        srcSet={
                            '/images/tents/small/' + props.photomain + ' 767.98px , /images/tents/large/' + props.photomain + ' 768px ,' 
                        }
                        src={ '/images/tents/large/' + props.photomain } 
                        alt={ props.type + " " + props.variation }
                    />

                    { tentPhotos.length > 0 && 
                    <img src="images/icon-gallery.svg" alt="" className="icon--svg"></img>
                    }
                </a>
            </div>
            <div className="tent-item--details">
                <h3>{props.variation}</h3>
                <p><strong>{props.size}</strong></p>
                <p className="title">Selected Extras:</p>
                <ul>
                
                { tentSelection.options.seating > 0 &&
                <li>Tables: { tentSelection.options.tables } { tentSelection.options.tableType ? 'Round' : 'Rectangular' }</li>
                }
                
                { tentSelection.options.seating > 0 &&
                <li>Seating: { tentSelection.options.seating } { tentSelection.options.seatingType ? 'Chairs' : 'Benches' }</li>
                }

                { tentSelection.options.seating < 1 &&
                <li>No Seating</li>
                }

                { tentSelection.options.woodenDanceFloor == 'yes' && props.danceFloorPrice > 0 &&
                <li>Wooden Dance Floor</li>
                }
                { tentSelection.options.optionlighting == 'yes' &&
                <li>Lighting </li>
                }
                { tentSelection.options.mattedFlooring == 'yes' &&
                <li>Matted Flooring</li>
                }
                { tentSelection.options.cateringTents  == 'yes' &&
                <li>Catering Tent</li>
                }
                { tentSelection.options.optionStage  == 'yes' &&
                <li>Stage</li>
                }
                { tentSelection.options.rusticLogBar  == 'yes' &&
                <li>Rustic Log Bar</li>
                }
                { tentSelection.options.woodenFirePit  == 'yes' &&
                <li>Wooden Fire Pit</li>
                }
                { tentSelection.options.chillOutPack  == 'yes' &&
                <li>Chill Out Pack</li>
                }
                { tentSelection.options.rusticCakeTable  == 'yes' &&
                <li>Rustic Cake Table</li>
                }
                { tentSelection.options.woodenBarrel.length > 0 &&
                <li>Wooden Barrels: { tentSelection.options.woodenBarrel }</li>
                }
                </ul>
                <div className="tent-item--footer">
                    <div className="tent-item--price">&pound;{ numberWithCommas(props.tentTotal) }
                    { props.addDelivery &&
                    <small>Inc. VAT, Delivery &amp; Insurance</small>
                    }
                    { !props.addDelivery &&
                    <small>Inc. VAT &amp; Insurance, Exc. Delivery</small>
                    }
                    </div>
                    <div className="tent-item--add">
                        <ShortlistButton tent={tentSelection} id={props.id} />
                    </div>
                    { props.recommended > 0 ? <div className="tent-item--recommended-msg"><img src="images/icon-star.svg" alt="" className="icon--svg"/>Recommended based on your event details</div> : null }
                </div>
            </div>
        </div>
    )
}


