const optionsList = [
    { id:1, optionID:'rectTables', optionPrice:19.83, },
	{ id:2, optionID:'benches', optionPrice:12.69, },
	{ id:3, optionID:'chairs', optionPrice:6.35, },
	{ id:4, optionID:'roundTables', optionPrice:14.54, },
	{ id:5, optionID:'woodenDanceFloor' },
	{ id:6, optionID:'optionlighting' },
	{ id:7, optionID:'mattedFlooring' },
	{ id:8, optionID:'mirrorBallSpotLights' },
	{ id:9, optionID:'cateringTents', optionPrice:612.00, },
	{ id:10, optionID:'optionStage', optionPrice:257.77, },
	{ id:11, optionID:'rusticLogBar', optionPrice:224.73, },
	{ id:12, optionID:'woodenFirePit', optionPrice:261.74, },
	{ id:13, optionID:'chillOutPack', optionPrice:330.48, },
	{ id:14, optionID:'rusticCakeTable', optionPrice:35.00, },
	{ id:15, optionID:'woodenBarrel', optionPrice:40.00, },
]

export default optionsList