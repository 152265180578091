import { EventOptionsSidebar } from './EventOptionsSidebar';
import { EventData } from '../features/event/EventData';
import { TentList } from '../features/tents/TentList';

export const EventOptions = () => {
    return (
        <div className="wrapper">
            <EventOptionsSidebar />
            <section className="step-2 step--selection">
                <EventData />            
                <TentList />
            </section>
        </div>
    )
}