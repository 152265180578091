import React from 'react';

const FormErrorsDisplay = ({ errors, ...props }) => {

    return (
        <div className="form-errors">
            <ul>
            { errors.map(function( error, i ) {
                return <li key={i}>{ error }</li>
            } ) }
            </ul>
        </div>
    );
};

export default FormErrorsDisplay;