import * as React from 'react';
import { useDispatch, useSelector } from "react-redux";
import { FilterTentsForm } from "./FilterTentsForm";
import { EventOptionsForm } from "./EventOptionsForm";
import { createTheme, ThemeProvider } from '@material-ui/core/styles';
import { SelectedTentList } from '../features/selectedTents/SelectedTents'
import { sidebarStatus } from '../features/sidebar/sidebarSlice';
//import Accordion from '@material-ui/core/Accordion';
//import AccordionSummary from '@material-ui/core/AccordionSummary';
//import AccordionDetails from '@material-ui/core/AccordionDetails';
//import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

// const accordionTheme = createTheme({
//   palette: {
//     mode: 'dark',
//      primary: {
//       contrastText: "#FFFFFF",
//       dark: "#FFFFFF",
//       main: "#FFFFFF",
//       light: "#FFFFFF",
//      },
//   },
//     overrides: {
//       MuiPaper: {
//         root: {
//           background: "none !important"
//         }
//       },
//       // Arrows
//       MuiIconButton: {
//           root: {
//               color: "#FFFFFF",
//           }
//       },
//     },
// })

const darkTheme = createTheme({
  palette: {
    mode: 'dark',
     primary: {
      contrastText: "#FFFFFF",
      dark: "#FFFFFF",
      main: "#FFFFFF",
      light: "#FFFFFF",
     },
  },
    overrides: {
      // Checkbox
      MuiSvgIcon: {
          root: {
              fill: "#FFFFFF",
          }
      },
      // Switch
      MuiButtonBase: {
        root: {
            color:  '#FFF !important',
        },
      },
      MuiSwitch: {
        track: {
          background: '#000 !important'
        }
      }
    },
});

export const EventOptionsSidebar = (props) => {

    const dispatch = useDispatch();

    // import tent data from store
    const selectedTents = useSelector((state) => state.selectedTents)
    const sidebar = useSelector((state) => state.sidebar);

    return (
      <>
        <button className="side-open" onClick={() => dispatch(sidebarStatus(1))}><span className="add-extras"><img src="/images/icon-plus.svg" alt="" className="icon--svg" width="12" height="12"/> extras</span><img src="/images/icon-tent.svg" height="16" width="16" className="tent-icon" /><span className="selected-tent-count">{ selectedTents.length > 0 ? selectedTents.length : "0" }</span></button>
        <div className={"side left" + ( sidebar.sidebarStatus ? " side--open" : " side--closed" ) }>
            <button onClick={() => dispatch(sidebarStatus(0))} className="side-close">
                <img src="/images/icon-close-side.svg" width="22" height="22" />
            </button>
            <div className="logo">
            <img src="/images/logo-euc-outline-2023.svg" alt="Events Under Canvas" width="131.363" height="60.75"></img>
            </div>
            <h1>Quote Builder</h1>
            <div className="side--block side--selection">
              <h3>Your Selection</h3>
              <SelectedTentList tents={selectedTents} />
            </div>
            <div className="side--block">
              <h3>Filter Tents</h3>
              <ThemeProvider theme={darkTheme}>
                <FilterTentsForm />
              </ThemeProvider>
            </div>
            <div className="side--block">
              <h3>Add &amp; Remove Items</h3>
              <ThemeProvider theme={darkTheme}>
                <EventOptionsForm />
              </ThemeProvider>
            </div>
        </div>
      </>
    )
}